@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: "GothamRounded";
		font-weight: 700;
		src: local("GothamRounded"),
			url(./fonts/GothamRounded-Bold.otf) format("opentype");
	}
	@font-face {
		font-family: "GothamRounded";
		font-weight: 500;
		src: local("GothamRounded"),
			url(./fonts/GothamRounded-Medium.otf) format("opentype");
	}
	@font-face {
		font-family: "GothamRounded";
		font-weight: 400;
		src: local("GothamRounded"),
			url(./fonts/GothamRounded-Book.otf) format("opentype");
	}
	@font-face {
		font-family: "GothamRounded";
		font-weight: 300;
		src: local("GothamRounded"),
			url(./fonts/GothamRounded-Light.otf) format("opentype");
	}
}

@media only screen and (max-width: 370px) {
	.custom-calendar {
		font-size: 8px !important;
	}
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
